import React, { Fragment } from 'react';
import get from 'lodash.get';
import { useStaticQuery, graphql } from 'gatsby';

import { getProductUrl } from 'helpers/url';

import {
  SharedStyles,
  Container,
  Slider,
  SliderFavorite3,
  ProductItem,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionTitle, StSectionMobileViewMore, StLink } = SharedStyles;
const { StSliderFavorite3 } = SliderFavorite3;

export default function ProductSlider(props) {
  let {
    productHandles,
    collectionHandle,
    title,
    bigTitle,
    viewAllLink,
  } = props;

  const {
    allShopifyProduct,
    allShopifyCollection,
    allContentfulProduct,
  } = useStaticQuery(graphql`
    query {
      allShopifyProduct {
        nodes {
          ...ShopifyProductFields
        }
      }
      allContentfulProduct {
        nodes {
          ...ContentfulProductFields
        }
      }
      allShopifyCollection {
        nodes {
          ...shopifyCollectionFields
        }
      }
    }
  `);

  if (collectionHandle) {
    let collection = allShopifyCollection.nodes.find(
      c => c.handle === collectionHandle
    );
    if (!collection) {
      console.error('ProductSlider: collection not found');
      return null;
    }

    productHandles = collection.products.map(p => p.handle);
  }
  if (!Array.isArray(productHandles) || productHandles.length === 0)
    return null;

  let products = productHandles.map(handle => {
    let shopifyProduct = allShopifyProduct.nodes.find(p => p.handle === handle);
    let contentfulProduct = allContentfulProduct.nodes.find(
      p => p.shopifyProduct.handle === handle
    );

    return {
      handle,
      shopifyProduct,
      contentfulProduct,
    };
  });

  let Wrapper = bigTitle ? StSectionMobileViewMore : Fragment;

  return (
    <Wrapper>
      {title && (
        <Container>
          {bigTitle ? (
            <StSectionTitle>
              <h2>{title}</h2>
              {viewAllLink && (
                <StLink>
                  <a href={viewAllLink}>{`VIEW ALL (${products.length})`}</a>
                </StLink>
              )}
            </StSectionTitle>
          ) : (
            <StSectionTitle alignLeft>
              <h3>{title}</h3>
            </StSectionTitle>
          )}
        </Container>
      )}
      <Slider settings={{ slidesPerView: 'auto' }}>
        {products.map((product, index) => {
          let { contentfulProduct, shopifyProduct, handle } = product;

          return (
            <StSliderFavorite3 key={`${product.id}_${index}`}>
              <ProductItem
                link={getProductUrl(handle)}
                img={get(shopifyProduct, 'images.0.localFile.publicURL')}
                title={get(shopifyProduct, 'title')}
                text={get(contentfulProduct, 'description')}
              />
            </StSliderFavorite3>
          );
        })}
      </Slider>
    </Wrapper>
  );
}
