import React from 'react';
import format from 'date-fns/format';

export function multiline(text) {
  return `${text}`.split('\n').reduce((arr, current, index) => {
    arr.push(`${current} `);
    arr.push(<br key={`br-${index}`} />);
    return arr;
  }, []);
}

export function multilineHTML(text) {
  return `${text}`.split('\n').join('<br />');
}

// 2020-08-24 => Aug 24, 2020
export function dateFormat(date) {
  return format(new Date(date), 'MMM d, Y');
}
