import React from 'react';
import get from 'lodash.get';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ReactMarkdown from 'react-markdown';

import ProductSlider from 'blocks/ProductSlider';

import {
  Spacing,
  Slider,
  SliderColorMelt,
  SliderFavorite3,
  SharedStyles,
  Container,
  VideoWithInfo,
  ProductItem,
  ListOfVideo,
} from '@matchbox-mobile/kenra-storybook';

import { getProductUrl, getVideoUrl } from 'helpers/url';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { multiline } from 'helpers/text';

const { StSliderColorMelt } = SliderColorMelt;
const { StSliderFavorite3 } = SliderFavorite3;

const {
  StSectionInfo,
  StTitle,
  StSectionMobileViewMore,
  StSectionTitle,
  StLink,
} = SharedStyles;

export default function Video(props) {
  let {
    video: {
      youtubeLink,
      title,
      showEvent,
      eventTitle,
      eventText,
      stepsTitle,
      steps,
      usedProducts,
      otherVideos,
    },
  } = props;

  let youTubeId = getYouTubeId(youtubeLink);

  return (
    <>
      <Spacing removeSpaceBottom>
        <StSectionInfo removeMobileBg desktopMarginSmall>
          <Container>
            <StTitle colorBlack>
              <h1>{multiline(title.title)}</h1>
            </StTitle>
          </Container>
        </StSectionInfo>
      </Spacing>

      <Spacing>
        <VideoWithInfo
          useImgOverlay
          img={getPreviewUrl(youTubeId)}
          channel="youtube"
          videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
          text={
            showEvent &&
            `
              <h3>${eventTitle}</h3>
              ${eventText && documentToHtmlString(eventText.json)}
            `
          }
          href="/"
        />
      </Spacing>

      {/* STEPS */}

      {Array.isArray(steps) && steps.length > 0 && (
        <Spacing>
          <StSectionMobileViewMore>
            <StSectionTitle>
              <Container>
                <h2>{stepsTitle}</h2>
                {/* <StLink mobileStyleLinkMore>
                  <a href="/">SMS PDF</a>
                </StLink> */}
              </Container>
            </StSectionTitle>

            <Slider
              settings={{
                slidesPerView: 'auto',
              }}
            >
              {steps.map((step, index) => (
                <StSliderColorMelt key={step.id}>
                  <img src={get(step, 'image.fixed.src')} alt="" />
                  <h3>{`Step ${index + 1}`}</h3>
                  <ReactMarkdown source={step.text.text} />
                </StSliderColorMelt>
              ))}
            </Slider>
          </StSectionMobileViewMore>
        </Spacing>
      )}

      {/* PRODUCTS USED */}

      {Array.isArray(usedProducts) && usedProducts.length > 0 && (
        <Spacing>
          <ProductSlider
            title="PRODUCTS USED"
            productHandles={usedProducts.map(p => p.shopifyProduct.handle)}
          />
        </Spacing>
      )}

      {/* OTHER VIDEOS */}

      {Array.isArray(otherVideos) && otherVideos.length > 0 && (
        <Spacing>
          <Container>
            <StSectionTitle alignLeft>
              <h3>YOU MAY ALSO LIKE</h3>
            </StSectionTitle>
          </Container>

          <ListOfVideo
            mobileScrollable
            items={otherVideos.map(video => {
              let { youtubeLink, title, slug, type } = video;
              let youTubeId = getYouTubeId(youtubeLink);

              return {
                img: getPreviewUrl(youTubeId),
                title: multiline(title.title),
                subtitle: type,
                href: getVideoUrl(slug),
              };
            })}
          />
        </Spacing>
      )}
    </>
  );
}
