import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Video from 'components/Video';

export default props => {
  let { contentfulVideo } = props.data;
  if (!contentfulVideo) return 'No video';

  return (
    <Layout>
      <Video video={contentfulVideo} />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulVideo(slug: { eq: $slug }) {
      ...ContentfulVideoFields
    }
  }
`;
